import React, { memo, RefObject, useEffect, useState } from 'react';

import styled from 'styled-components';

import { useGetTimeLeft } from 'hooks';
import { ColorService, desktop, getFontFamily, tablet } from 'services';

interface IStickyCounterProps {
  formRef: RefObject<HTMLDivElement>;
  hasDiscountInfo?: boolean;
  discountPrice?: string;
  oldPrice?: string;
  perMonth?: string;
  oldPerMonth?: string;
  className?: string;
}

export const StickyCounter = memo(
  ({
    className,
    formRef,
    hasDiscountInfo,
    discountPrice,
    oldPrice,
    perMonth,
    oldPerMonth,
  }: IStickyCounterProps) => {
    const timeLeft = useGetTimeLeft();
    const [formScrollPosition, setFormScrollPosition] = useState<number>(0);
    const [opacity, setOpacity] = useState<number>(1);

    useEffect(() => {
      setTimeout(() => {
        if (formRef.current) {
          const formPosition =
            formRef.current.getBoundingClientRect().top + window.scrollY;
          setFormScrollPosition(formPosition);
        }

        const handleScroll = () => {
          if (hasDiscountInfo) {
            setOpacity(() =>
              window.scrollY < formScrollPosition - 850 ? 1 : 0,
            );
          }
          if (!hasDiscountInfo) {
            setOpacity(() =>
              window.scrollY < formScrollPosition - 700 ? 1 : 0,
            );
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, 0);
    }, [formRef, formScrollPosition, hasDiscountInfo]);

    const onClick = () => {
      if (formRef.current) {
        formRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    return (
      <Wrapper className={className} opacity={opacity}>
        {hasDiscountInfo && (
          <DiscountInfoWrapper>
            <MonthPriceWrapper>
              <Text>Рассрочка на 18 месяцев</Text>
              <Prices>
                <MonthPrice>{perMonth} BYN</MonthPrice>
                <DiscountMonthPrice>{oldPerMonth} BYN</DiscountMonthPrice>
              </Prices>
            </MonthPriceWrapper>
            <Line />
            <FullPriceWrapper>
              <Text>Стоимость курса</Text>
              <Prices>
                <DiscountPrice>{discountPrice}</DiscountPrice>
                <OldPrice>{oldPrice}</OldPrice>
              </Prices>
            </FullPriceWrapper>
          </DiscountInfoWrapper>
        )}

        <CounterWrap>
          <Counter>
            <Number>{timeLeft.days}</Number> : <Number>{timeLeft.hours}</Number>{' '}
            : <Number>{timeLeft.minutes}</Number> :{' '}
            <Number>{timeLeft.seconds}</Number>
          </Counter>
          <StyledButton onClick={onClick}>Записаться</StyledButton>
        </CounterWrap>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ opacity: number }>`
  background-color: ${ColorService.BG_GRAY};
  border-top: 1px solid ${ColorService.SILVER};
  width: 100%;
  padding: 24px 32px;
  border-radius: 24px 24px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 6;
  display: flex;
  flex-direction: column;
  opacity: ${({ opacity }) => opacity};
  transition: 0.6s;

  ${tablet} {
    padding: 32px 40px;
  }

  ${desktop} {
    display: none;
  }
`;

const CounterWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Counter = styled.div`
  box-sizing: content-box;
  display: flex;
  font-family: ${getFontFamily('bold')};
  font-size: 24px;
  line-height: 24px;
  color: ${ColorService.YELLOW};
  min-width: 144px;

  ${tablet} {
    min-width: 156px;
    padding: 0 96px;
  }
`;

const Number = styled.p``;

const StyledButton = styled.button`
  width: 100%;
  height: 48px;
  background: ${ColorService.YELLOW};
  border-radius: 8px;
  font-family: ${getFontFamily('medium')};
  font-size: 16px;
  line-height: 20px;
  border: none;
  color: ${ColorService.MAIN_BLACK};
  cursor: pointer;

  &:hover {
    transition: 0.6s;
    background: ${ColorService.YELLOW_LEMON};
  }

  &:active {
    transition: 0.6s;
    background: ${ColorService.MUSTARD};
  }
`;

const DiscountInfoWrapper = styled.div`
  display: flex;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${ColorService.SILVER};

  ${tablet} {
    justify-content: space-around;
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
`;

const Line = styled.div`
  ${tablet} {
    width: 1px;
    height: 72px;
    background-color: ${ColorService.SILVER};
  }
`;

const Text = styled.p`
  color: ${ColorService.GRAY};
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: baseline;

  ${tablet} {
    flex-direction: column-reverse;
  }
`;

const FullPriceWrapper = styled(PriceWrapper)`
  display: none;

  ${tablet} {
    display: flex;
  }
`;

const MonthPriceWrapper = styled(PriceWrapper)``;

const Prices = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

const DiscountPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 18px;
  line-height: 36px;
`;

const OldPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 18px;
  line-height: 36px;
  text-decoration-line: line-through;
`;

const MonthPrice = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 24px;
  line-height: 36px;
  color: ${ColorService.YELLOW};

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;

const DiscountMonthPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 18px;
  line-height: 36px;
  text-decoration-line: line-through;
`;
