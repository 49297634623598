import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ColorService, getFontFamily } from 'services';
import { Icon, IconsNames } from 'components/atoms';

interface IBtnGoBack {
  title?: string;
}

export const BtnGoBack = memo(({ title = 'Назад' }: IBtnGoBack) => {
  const navigate = useNavigate();

  return (
    <WrapperLink>
      <StyledLink onClick={() => navigate(-1)}>
        <IconArrow>
          <Icon name={IconsNames.arrow_white} />
        </IconArrow>
        {title}
      </StyledLink>
    </WrapperLink>
  );
});

const WrapperLink = styled.div`
  margin-bottom: 40px;
`;

const StyledLink = styled.button`
  background: ${ColorService.TRANSPARENT};
  border: 1px solid ${ColorService.GRAY};
  box-sizing: border-box;
  border-radius: 8px;
  font-family: ${getFontFamily()};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${ColorService.WHITE};
  vertical-align: sub;
  padding-right: 16px;
  position: relative;
  z-index: 1;
  height: 32px;
  width: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transition: 0.6s;
    background: ${ColorService.YELLOW_LEMON};
    border: 1px solid ${ColorService.YELLOW_LEMON};
  }
`;

const IconArrow = styled.div`
  cursor: pointer;
  transform: rotate(180deg);
  height: 16px;
  width: 16px;
`;
