import React, { memo } from 'react';

import styled from 'styled-components';

import { Icon, IconsNames } from 'components/atoms';
import { ColorService, desktop, getFontFamily, tablet } from 'services';

interface IInfoCardsTabletProps {
  className?: string;
}

export const InfoCardsTablet = memo(({ className }: IInfoCardsTabletProps) => (
  <TabletBlock className={className}>
    <TabletBlockCard>
      <Rating>
        4,9 <Icon name={IconsNames.star} width={32} height={32} />
      </Rating>
      <InfoText>
        На основании более 1000+ отзывов на Google, Yandex, Relax, TutorTop и
        др. независмых площадках.
      </InfoText>
    </TabletBlockCard>
    <TabletBlockCard>
      <Graduation>11 500+</Graduation>
      <InfoText>
        Выпустили айтишников, которые успешно начали свой путь в международных
        IT компаниях.
      </InfoText>
    </TabletBlockCard>
  </TabletBlock>
));

const TabletBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${tablet} {
    flex-direction: row;
  }

  ${desktop} {
    display: none;
  }
`;

const TabletBlockCard = styled.div`
  background-color: ${ColorService.BG_GRAY};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
`;

const Rating = styled.h4`
  font-size: 54px;
  line-height: 62px;
  font-family: ${getFontFamily('bold')};
  color: ${ColorService.YELLOW};
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;

const Graduation = styled.h4`
  color: ${ColorService.WHITE};
  font-size: 54px;
  line-height: 62px;
  font-family: ${getFontFamily('bold')};
  margin-bottom: 4px;
`;

const InfoText = styled.p`
  color: ${ColorService.WHITE};
  font-size: 14px;
  line-height: 20px;
`;
