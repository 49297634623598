import React, { memo } from 'react';

import styled from 'styled-components';
import { ColorService, getFontFamily, tablet } from 'services';
import { useGetTimeLeft } from 'hooks';

interface ICounterProps {
  className?: string;
}

export const Counter = memo(({ className }: ICounterProps) => {
  const timeLeft = useGetTimeLeft();

  return (
    <CounterContainer className={className}>
      <Wrapper>
        <CountWrapper>
          <Number>{timeLeft.days}</Number>
          <Interval>Дней</Interval>
        </CountWrapper>

        <Line />

        <CountWrapper>
          <Number>{timeLeft.hours}</Number>
          <Interval>Часов</Interval>
        </CountWrapper>

        <Line />

        <CountWrapper>
          <Number>{timeLeft.minutes}</Number>
          <Interval>Минут</Interval>
        </CountWrapper>

        <Line />

        <CountWrapper>
          <Number>{timeLeft.seconds}</Number>
          <Interval>Секунд</Interval>
        </CountWrapper>
      </Wrapper>
    </CounterContainer>
  );
});

const CounterContainer = styled.div`
  background-color: ${ColorService.BG_BLACK};
  border-radius: 16px;
  color: ${ColorService.WHITE};
  padding: 12px 25px;
  display: flex;
  justify-content: center;

  ${tablet} {
    padding: 12px 40px;
  }
`;

const Wrapper = styled.div`
  width: 334px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${ColorService.GRAY_LIGHT};
`;

const CountWrapper = styled.div`
  width: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Number = styled.h3`
  font-family: ${getFontFamily('bold')};
  font-size: 32px;
  line-height: 44px;
  color: ${ColorService.YELLOW};
`;

const Interval = styled.p`
  color: ${ColorService.GRAY};
  font-size: 12px;
  line-height: 18px;
`;
