import React, { memo } from 'react';

import styled from 'styled-components';

import { CourseCard } from 'components/atoms';
import { tablet } from 'services';
import { AllCourses, HitCourses } from 'context';

export const Hits = memo(() => {
  const HitCoursesInfo = AllCourses.filter(({ key }) =>
    HitCourses.includes(key),
  );

  return (
    <Wrapper>
      {HitCoursesInfo.map(
        ({
          key,
          courseName,
          categoryName,
          discountPrice,
          oldPrice,
          img,
          link,
          benefitIcon,
        }) => (
          <StyledCard
            key={key}
            categoryName={categoryName}
            course={courseName}
            discountPrice={discountPrice}
            oldPrice={oldPrice}
            addFireIcon
            img={img}
            link={link}
            benefitIcon={benefitIcon}
          />
        ),
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  ${tablet} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
`;

const StyledCard = styled(CourseCard)`
  flex-grow: 1;
`;
