import React, { memo, useState, useEffect } from 'react';

import styled from 'styled-components';
import { ColorService, getFontFamily, tablet } from 'services';

interface ICounterProps {
  className?: string;
}

export const DailyCounter = memo(({ className }: ICounterProps) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: '0',
    minutes: '0',
    seconds: '0',
  });

  const now = new Date();
  const options = { timeZone: 'Europe/Minsk' };
  const nowInMinsk = now.toLocaleString('en-US', options);

  const tomorrow = new Date(nowInMinsk);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  const difference = +tomorrow - +new Date(nowInMinsk);

  useEffect(() => {
    const id = setTimeout(() => {
      if (difference > 0) {
        setTimeLeft(() => {
          const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((difference / 1000 / 60) % 60);
          const seconds = Math.floor((difference / 1000) % 60);

          return {
            hours: hours < 10 ? `0${hours}` : `${hours}`,
            minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
            seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
          };
        });
      }
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  return (
    <Wrapper className={className}>
      <Number>{timeLeft.hours}</Number>:<Number>{timeLeft.minutes}</Number>:
      <Number>{timeLeft.seconds}</Number>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 148px;
  background-color: ${ColorService.RED};
  border-radius: 8px;
  color: ${ColorService.WHITE};
  padding: 12px;
  font-family: ${getFontFamily('medium')};
  font-size: 24px;
  line-height: 24px;

  ${tablet} {
    width: unset;
    justify-content: flex-start;
  }
`;

const Number = styled.div`
  min-width: 34px;
  text-align: center;
`;
