import React, { memo } from 'react';

import styled from 'styled-components';

import { getFontFamily, ColorService, tablet, laptop_small } from 'services';
import { ContactForm } from 'components/molecules';

export const ConsultationForm = memo(() => (
  <FormWrapper>
    <Form>
      <Title>Поможем в выборе!</Title>
      <Subtitle>
        Если у вас есть вопросы о формате или вы не знаете что выбрать, оставьте
        свой номер: <br />
        мы позвоним, чтобы ответить на все ваши вопросы.
      </Subtitle>
      <Descriptions>
        {['Звонок', 'Консультация', 'Курсы']?.map((point) => (
          <Block key={point}>{point}</Block>
        ))}
      </Descriptions>
      <ContactForm
        titleBtn="Записаться"
        text="Свяжемся в течение рабочего дня"
      />
    </Form>
  </FormWrapper>
));

const Form = styled.form`
  text-align: center;

  ${tablet} {
    text-align: left;
  }
`;

const FormWrapper = styled.div`
  background: ${ColorService.BG_GRAY};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  padding: 40px 20px;
  position: relative;
  align-items: flex-end;
  border: 1px solid ${ColorService.SILVER};
  margin: 0 -16px;

  ${tablet} {
    padding: 36px 65px;
    margin: 0;
    width: 100%;
  }
`;

const Subtitle = styled.p`
  font-family: ${getFontFamily()};

  font-size: 14px;
  line-height: 21px;

  color: ${ColorService.WHITE};
  margin: 17px 0 40px;

  ${tablet} {
    font-size: 18px;
    line-height: 24px;
    margin: 16px 0 24px;
  }
`;

const Descriptions = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 24px -15px;
  justify-content: center;

  ${tablet} {
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 0 8px -15px;
  }

  ${laptop_small} {
    flex-direction: row;
  }
`;

const Block = styled.li`
  font-family: ${getFontFamily('bold')};
  font-size: 42px;
  line-height: 54px;

  color: ${ColorService.WHITE};

  padding-right: 25px;
  position: relative;
  top: 0;
  left: 15px;
  flex-basis: 100%;

  ${tablet} {
    margin-bottom: 16px;
    font-size: 54px;
    line-height: 54px;
    text-align: left;
    max-width: max-content;

    &:before {
      position: absolute;
      right: -50px;
      top: 30px;
      content: '';
      height: 1px;
      width: 42px;
      background: ${ColorService.WHITE};
    }

    &:last-of-type {
      padding-right: 0;
      &:before {
        content: none;
      }
    }
    &:first-of-type {
      max-width: 220px;
    }
  }

  ${laptop_small} {
    flex-basis: 33%;
    margin-bottom: 0;
    &:not(:first-of-type) {
      padding-left: 82px;
    }
  }
`;

const Title = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 28px;
  line-height: 30px;
  color: ${ColorService.WHITE};

  ${tablet} {
    font-size: 32px;
    line-height: 44px;
  }
`;
