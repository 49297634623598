// export const mobile_small = '@media (min-width: 400px)';
// export const mobile = '@media (min-width: 415px)';
// export const sidebarPoint = '@media (min-width: 600px)';
export const tablet = '@media (min-width: 768px)';
export const tablet_middle = '@media (min-width: 800px)';
export const desktop = '@media (min-width: 992px)';
export const laptop_small = '@media (min-width: 1220px)';
export const large_desktop = '@media (min-width: 1290px)';
export const max_desktop = '@media (min-width: 1500px)';
export const max_desktop1400 = '@media (min-width: 1400px)';
