class ColorApiService {
  public TRANSPARENT = 'transparent';

  public WHITE = '#FFFFFF';

  public BLACK = '#000000';

  public MAIN_BLACK = '#070707';

  public GRAY = '#ACB1B6';

  public SILVER = '#575A61';

  public YELLOW = '#FFDA17';

  public MUSTARD = '#F0BE0C';

  public BG_GRAY = '#272B35';

  public BG_GRAY_ADDITIONAL = '#373A43';

  public BG_BLACK = '#11141D';

  public YELLOW_LEMON = '#FFE880';

  public GRAY_LIGHT = '#767A7F';

  public GRAY_DARK = '#3C4049';

  public ADDITIONAL_GRAY = '#3C4049';

  public RED = '#DE340F';
}

export const ColorService = new ColorApiService();
