import React, { memo, useState, useEffect, useRef, useCallback } from 'react';

import { Link } from 'react-router-dom';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import InputMask from 'react-input-mask';

import styled, { css } from 'styled-components';

import {
  getFontFamily,
  ColorService,
  tablet,
  desktop,
  laptop_small,
} from 'services';
import { PATHS } from 'router/config';
import { useGeoLocation, useCreateLead } from 'hooks';
import { SuccessFormModal } from 'components/molecules';
import { Button } from 'components/atoms';
import { ErrorForm } from './ErrorForm';

const PhoneInputCountry = PhoneInput as React.FC<
  PhoneInputProps & { ref: React.RefObject<HTMLInputElement> }
>;

interface IContactForm {
  titleBtn: string;
  text: string;
  formTitle?: string;
  id?: string;
}

const validatePhoneLength = (phone: string, mask: string) => {
  if (mask === '999 999 999 999 99') {
    return phone.length > 6;
  }
  const phoneLength = (mask.match(/9/g) || []).length;
  return phone.length === phoneLength;
};

export const ContactForm = memo(
  ({ titleBtn, text, formTitle }: IContactForm) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mask, setMask] = useState('(99) 999-99-99');
    const [placeholder, setPlaceholder] = useState('(99) 999-99-99');
    const [phone, setPhone] = useState('');
    const [isSuccessSendForm, setIsSuccessSendForm] = useState(false);
    const [isErrorSendForm, setIsErrorSendForm] = useState(false);

    const { countryCode, phoneCode } = useGeoLocation();
    const [countryCodeInput, setCountryCodeInput] = useState(countryCode);
    const [phoneCodeInput, setPhoneCodeInput] = useState(phoneCode);
    const [errorPhoneLength, setErrorPhoneLength] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorName, setErrorName] = useState(false);

    const countryRef = useRef() as React.RefObject<HTMLInputElement>;

    const formattedPlaceholder = useCallback((format: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [code, ...phonePart] = format.split(' ');
      return phonePart.join(' ').replace(/\./gi, '_');
    }, []);

    const formattedMask = useCallback(
      // eslint-disable-next-line no-useless-escape
      (format: string) => format.replace(/\_/gi, '9'),
      [],
    );

    const [createLeadMutation, { success, error, isLoading }] = useCreateLead({
      lead: {
        phone: `${phoneCodeInput}${phone}`,
        name,
        email,
        tittle: `Lead from: ${window.location}`,
        formName: 'form_bf',
        roistatId: 'nocookie',
      },
    });

    const onSubmitFormClick = async () => {
      const emailRegExp =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      const phoneNumber = phone.replace(/[^0-9]/g, '');
      const isValidEmail = emailRegExp.test(email);
      const isValidPhone = validatePhoneLength(phoneNumber, mask);

      if (!isValidPhone || name.length === 0 || !isValidEmail) {
        setErrorPhoneLength(!isValidPhone);
        setErrorName(name.length === 0);
        setErrorEmail(!isValidEmail);
        return;
      }

      setErrorPhoneLength(false);
      setErrorName(false);
      setErrorEmail(false);
      createLeadMutation();
    };

    useEffect(() => {
      setIsErrorSendForm(error);
    }, [error]);

    useEffect(() => {
      setIsSuccessSendForm(success);
      if (success) {
        // @ts-ignore
        window.dataLayer.push({ event: 'order_backend' });
      }
    }, [success]);

    useEffect(() => {
      setCountryCodeInput(countryCode);
      setPhoneCodeInput(phoneCode);
    }, [countryCode, phoneCode]);

    useEffect(() => {
      const ref = countryRef?.current as any;

      if (ref?.state?.selectedCountry?.format) {
        setTimeout(() => {
          const newPlaceholder = formattedPlaceholder(
            ref.state?.selectedCountry?.format,
          );
          setPlaceholder(newPlaceholder);
          const newMask = formattedMask(newPlaceholder);
          setMask(newMask);
        }, 600);
      }
    }, [countryRef, formattedPlaceholder, countryCodeInput, formattedMask]);

    const changeCountry = (data: CountryData) => {
      const {
        dialCode,
        format,
        countryCode: selectedCountryCode,
      } = data as CountryData;
      const newPlaceholder = formattedPlaceholder(format);
      setPlaceholder(newPlaceholder);
      const newMask = formattedMask(newPlaceholder);
      setMask(newMask);
      setPhone('');
      setPhoneCodeInput(`+${dialCode}`);
      setCountryCodeInput(selectedCountryCode.toUpperCase());
      setErrorPhoneLength(false);
    };

    const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPhone(event.target.value);
      setErrorPhoneLength(false);
    };

    const onChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      field: string,
    ) => {
      if (field === 'email') {
        setEmail(event.target.value);
      }

      if (field === 'name') {
        setName(event.target.value);
      }
    };

    const pressTryAgain = () => {
      setPhone('');
      setName('');
      setEmail('');
      setErrorPhoneLength(false);
      setIsSuccessSendForm(false);
      setIsErrorSendForm(false);
    };

    const onHideModalClick = () => {
      setIsSuccessSendForm(false);
      setPhone('');
      setName('');
      setEmail('');
    };

    return (
      <>
        {formTitle && <FormTitle>{formTitle}</FormTitle>}
        <Box>
          <Form>
            <InputWrapper>
              <StyledInput
                value={name}
                placeholder="Имя"
                name="name"
                onChange={(event) => onChange(event, 'name')}
                valid={!errorName}
              />
              {errorName && <ErrorText>Пожалуйста, заполните имя</ErrorText>}
            </InputWrapper>

            <InputWrapper>
              <StyledInput
                value={email}
                placeholder="Email"
                name="email"
                onChange={(event) => onChange(event, 'email')}
                valid={!errorEmail}
              />
              {errorEmail && (
                <ErrorText>Пожалуйста, введите корректный email</ErrorText>
              )}
            </InputWrapper>

            <PhoneInputWrapper>
              {countryCodeInput && (
                <PhoneInputCountry
                  ref={countryRef}
                  country={countryCodeInput.toLowerCase()}
                  value={phone}
                  onChange={(value, data) => changeCountry(data as CountryData)}
                  preferredCountries={[
                    'ru',
                    'by',
                    'ge',
                    'kz',
                    'lv',
                    'lt',
                    'pl',
                  ]}
                />
              )}
              <PhoneField>
                <PhoneBox>
                  <CountryCodeButton>
                    {`${countryCodeInput} ${phoneCodeInput}`}
                  </CountryCodeButton>
                  <StyledInputMask
                    placeholder={placeholder}
                    mask={mask}
                    onChange={(event) => onChangePhone(event)}
                    value={phone}
                    valid={!errorPhoneLength}
                  />
                </PhoneBox>
                {errorPhoneLength && (
                  <ErrorText>
                    {phone.length
                      ? 'Номер телефона короткий, исправьте его пожалуйста'
                      : 'Пожалуйста, заполните номер телефона'}
                  </ErrorText>
                )}
              </PhoneField>
            </PhoneInputWrapper>

            <StyledButton
              type="button"
              loading={isLoading}
              onClick={onSubmitFormClick}
            >
              {isLoading ? (
                <div className="spinner-border" role="status" />
              ) : (
                <span>{titleBtn}</span>
              )}
            </StyledButton>
          </Form>

          {isErrorSendForm && <StyledErrorForm pressTryAgain={pressTryAgain} />}

          <AdditionalInfo>
            <Hint>
              Нажимая на кнопку, я соглашаюсь на{' '}
              <StyledLink>
                <Link to={PATHS.privacy}>обработку персональных данных</Link>
              </StyledLink>
            </Hint>
            <FormText>{text}</FormText>
          </AdditionalInfo>
        </Box>

        {isSuccessSendForm && (
          <SuccessFormModal
            closeOnBackdropClick
            isShown={isSuccessSendForm}
            hide={onHideModalClick}
          />
        )}
      </>
    );
  },
);

export const Box = styled.div`
  width: 100%;
  padding: 32px 0 32px;
`;

const PhoneBox = styled.div`
  display: flex;
  width: 100%;

  ${tablet} {
    width: auto;
  }
`;

export const ErrorText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-family: ${getFontFamily()};
  color: ${ColorService.RED};
  text-align: left;
  padding-top: 6px;

  ${laptop_small} {
    max-width: 240px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  margin-bottom: 8px;
  gap: 12px;

  ${laptop_small} {
    flex-direction: row;
    gap: 8px;
  }
`;

export const PhoneInputWrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div``;

export const StyledInput = styled.input<{ valid: boolean }>`
  ${({ valid }) => css`
    background: ${ColorService.WHITE};
    border: 1px solid ${valid ? ColorService.GRAY : ColorService.RED};
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    font-size: 16px;
    line-height: 21px;
    color: ${ColorService.MAIN_BLACK};
    font-family: ${getFontFamily()};
    outline: none;
    z-index: 1;
    border-radius: 8px;

    &:focus-visible {
      background: ${ColorService.WHITE};
      border: 1px solid ${ColorService.YELLOW};
    }

    ${laptop_small} {
      width: 240px;
    }
  `}
`;

const PhoneField = styled.div``;

export const CountryCodeButton = styled.button`
  background: ${ColorService.WHITE};
  border: none;
  box-sizing: border-box;
  text-align: left;
  padding-left: 12px;
  font-size: 16px;
  line-height: 21px;
  min-width: 94px;
  height: 48px;
  font-family: ${getFontFamily()};
  color: ${ColorService.MAIN_BLACK};
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
`;

export const StyledInputMask = styled(InputMask)<{ valid: boolean }>`
  ${({ valid }) => css`
    background: ${ColorService.WHITE};
    border: 1px solid ${valid ? ColorService.GRAY : ColorService.RED};
    box-sizing: border-box;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    outline: none;
    margin-left: -1px;
    z-index: 1;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    &:focus-visible {
      background: ${ColorService.WHITE};
      border: 1px solid ${ColorService.YELLOW};
    }

    ${laptop_small} {
      width: 154px;
    }
  `}
`;

export const StyledButton = styled(Button)<{ loading: boolean }>`
  ${({ loading }) => css`
    background: ${loading ? ColorService.MUSTARD : ColorService.YELLOW};
    cursor: ${loading ? 'not-allowed !important' : 'pointer'};
    &:hover {
      background: ${loading ? ColorService.MUSTARD : ColorService.YELLOW_LEMON};
    }
  `}
`;

export const FormText = styled.p`
  font-family: ${getFontFamily()};
  font-size: 12px;
  line-height: 18px;
  color: ${ColorService.GRAY};
  margin-top: 4px;
  display: none;

  ${laptop_small} {
    display: block;
  }
`;

const Subtitle = styled.p`
  font-family: ${getFontFamily()};
  font-size: 14px;
  line-height: 21px;
  color: ${ColorService.WHITE};
  padding: 20px 0 0;
  max-width: 615px;
`;

export const Hint = styled(Subtitle)`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 0;

  ${tablet} {
    text-align: left;
    max-width: initial;
  }
`;

const StyledLink = styled.span`
  a {
    text-decoration: underline;
  }
  &:hover a {
    transition: 0.6s;
    color: ${ColorService.MUSTARD} !important;
  }
`;

const FormTitle = styled.p`
  font-size: 28px;
  line-height: 30px;
  font-family: ${getFontFamily('bold')};
  color: ${ColorService.WHITE};
  text-align: center;
  margin: 40px 0 -12px;

  ${tablet} {
    text-align: left;
    font-size: 32px;
    line-height: 44px;
    margin: 69px 0 -16px;
    position: relative;
  }

  ${desktop} {
    margin: 79px 0 -16px;
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledErrorForm = styled(ErrorForm)``;
