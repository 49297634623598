import React, { memo } from 'react';

import styled from 'styled-components';

interface IMarginHorizontal {
  children: React.ReactNode;
}

export const MarginHorizontal = memo(({ children }: IMarginHorizontal) => (
  <ContainerMarginStyled>{children}</ContainerMarginStyled>
));

export const ContainerMarginStyled = styled.div`
  padding: 40px 0 50px;
`;
