import React, { memo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Main, Course, Courses } from 'components/pages';
import { CoverTemplate, MainTemplate } from 'components/templates';

import { ScrollToTop } from 'hooks';
import { PATHS } from './config';

export const AppRouter = memo(() => (
  <ScrollToTop>
    <Routes>
      <Route
        path={PATHS.main}
        element={
          <CoverTemplate>
            <Main />
          </CoverTemplate>
        }
      />
      <Route
        path={PATHS.courses}
        element={
          <MainTemplate>
            <Courses />
          </MainTemplate>
        }
      />
      <Route
        path={PATHS.course}
        element={
          <MainTemplate>
            <Course />
          </MainTemplate>
        }
      />

      <Route path="*" element={<Navigate to={PATHS.main} />} />
    </Routes>
  </ScrollToTop>
));
